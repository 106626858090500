import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//路由守卫（路由跳转权限）
import './router/before';

//引入elementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//初始化全局样式
import './style/base.scss'

//全局样式
import './style/index.scss'

//svg图片
import './svgIcons/index'

//全局引入按钮组件
import TemBtn from './components/button/index'

//调用加密
import RSA from '@/utils/rsa.js'

Vue.prototype.RSA = RSA;

Vue.use(TemBtn)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


