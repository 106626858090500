<template>
  <div :class="['table-components x-flex-y', {'remove-header-bg': removeHeaderBg}]" data-automation="table">
    <div class="table-operate" v-if="myTable.configItem">
        <div class="operate" >
            <tem-btn class="operate-btn" v-for="(item,index) in myTable.operateBtns"  :type="item.type" @click="item.event"  :value="item.value"></tem-btn>
        </div>
        <div class="config" v-if="showConfig">
            <el-popover placement="bottom" width="280" trigger="click">
                <div class="config-box">
                <p v-if="item.label" v-for="(item, key) in header" :key="key">
                    <el-checkbox v-if="item.hasOwnProperty('ifShow')" v-model="item.ifShow">{{ item.label}}</el-checkbox>
                </p>
                </div>
                <div slot="reference">
                    <svg-icon class="svg-icon" icon-class="icon-config" size="16"></svg-icon>
                    <span style="font-size:14px;color:#3270FF;cursor:pointer;margin-left: 4px;">筛选</span>
                </div>
            </el-popover>
        </div>
    </div>
    <el-table ref="elTable" :border="myTable.border" :stripe="myTable.stripe" v-loading="loading"
      :data=" (myTable.frontPage && myTable.data.length > 0) ? myTable.data.slice((currentPage-1)*pageSize,currentPage*pageSize): myTable.data"
      :max-height="myTable.maxHeight" :height="myTable.height" :highlight-current-row="myTable.highlightCurrentRow"
      :row-class-name="myTable.rowClassName" :row-key="myTable.rowKey" :tree-props="myTable.treeProps" @select="select"
      @select-all="selectAll" @selection-change="selectionChange" @row-click="rowClick" :cell-leftstyle="cellStyle"
      @sort-change="changeSort"
      @header-click="headerClick"
      @cell-click="cellClick">
      <template slot="empty">
          <img src="../../assets/icon_nodata.png" width="80px" height="80px" style="margin-top:80px;" alt >
          <p style="line-height:1; padding:0;margin-top:16px;margin-bottom:80px;">暂无数据</p>
      </template>
      <template v-for="(item, key) in header">
        <template v-if="item.ifShow">
          <el-table-column v-if="item.header" :prop="item.prop" :width="item.width" :min-width="item.minWidth"
            :align="item.align || 'left'" :fixed="item.fixed" :show-overflow-tooltip="item.tooltip" :sortable="item.sortable"
            :formatter="item.formatter">
            <Cell slot="header" slot-scope="scope" :row="scope.row" :index="scope.$index" :render="item.header"></Cell>
            <Cell v-if="item.render" slot-scope="scope" :row="scope.row" :index="scope.$index" :render="item.render">
            </Cell>
          </el-table-column>
          <el-table-column v-else-if="item.render " :prop="item.prop" :label="item.label" :width="item.width"
            :min-width="item.minWidth" :align="item.align || 'left'" :fixed="item.fixed"
            :show-overflow-tooltip="item.tooltip" :formatter="item.formatter"
            :sortable="item.sortable">
            <Cell slot-scope="scope" :row="scope.row" :index="scope.$index" :render="item.render"></Cell>
          </el-table-column>
          <el-table-column v-else-if="item.type" :type="item.type" :label="item.label" :selectable="item.selectable" :sortable="item.sortable"
            :width="item.width" :min-width="item.minWidth" align="left" :fixed="item.fixed" :index='indexUp'>
          </el-table-column>
          <el-table-column v-else-if="item.formatter" :prop="item.prop" :label="item.label" :width="item.width"
            :min-width="item.minWidth" :align="item.align || 'left'" :fixed="item.fixed"
            :show-overflow-tooltip="item.tooltip" :formatter="item.formatter"
            :sortable="item.sortable"></el-table-column>
          <el-table-column v-else :prop="item.prop" :label="item.label" :width="item.width" :min-width="item.minWidth"
            :align="item.align || 'left'" :fixed="item.fixed" :show-overflow-tooltip="item.tooltip"
            :sortable="item.sortable">
            <template slot-scope="scope">
              {{ ( scope.row[item.prop] === '' || scope.row[item.prop] == null || scope.row[item.prop] == 'undifined' ) ? '--' : scope.row[item.prop] }}
            </template>
          </el-table-column>
        </template>
      </template>
    </el-table>
    <div class="last" v-if="myTable.expand">
      <div class="last-title">总计</div>
      <div class="last-content" v-for="(item,index) in myTable.expand">
        <span>{{item.label}}：{{item.value}}</span>
      </div>
    </div>
    <div class="pagination" v-if="showPage && total > 0">
      <el-pagination background :current-page.sync="currentPage" :page-size.sync="pageSize" :page-sizes="sizes" :total="total"
        :layout="layout" v-bind="$attrs" @size-change="handleSizeChange" @current-change="handleCurrentChange" >
        <span v-if="layout.indexOf('sizes') > -1" class="slot-page">跳至<el-input  @paste.native.capture.prevent="handlePaste" class="page-input" v-model.number="inputPageNum" @blur="inputBlur " @input="handlePageNum"></el-input> 页 <span class="btn" @click="handleCurrentChange(inputPageNum)">跳转</span></span>
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import Cell from './render.js';
  export default {
    name: 'MyTable',
    components: {
      Cell,
    },
    props: {
      removeHeaderBg: { // 是否去除表头背景色
        type: Boolean,
        default: false
      },
      selection: { // 可勾选
        type: Boolean,
        default: false
      },
      index: { // 索引
        type: Boolean,
        default: false
      },
      table: { // 表格数据
        type: Object,
        default: () => {
          return {
            maxHeight: '100px',
            height:'536px'
          };
        }
      },
      showPage: { // 显示分页
        type: Boolean,
        default: true
      },
      showConfig:{
          type: Boolean,
          default: true
      },
      total: { // 总条数
        type: Number,
        default: 100
      },
      layout: {
        type: String,
        default: 'total, prev, pager, next, sizes,slot'
      },
      sizes: { // 页容量
        type: Array,
        default: () => {
          return [10, 20, 50];
        }
      },
      page: { // 当前页
        type: Number,
        default: 1
      },
      size: {
        type: Number,
        default: 10
      },
      rowKey: {
        type: String,
        default: 'id'
      },
      loading:{
          type: Boolean,
          default:false
      },
      treeProps: {
        type: Object,
        default: () => {
          return {
            children: 'children',
            hasChildren: 'hasChildren'
          };
        }
      },
    },
    computed: {
        //设置表格对象默认值
        myTable() {
            return Object.assign({
                maxHeight: '536px',
                border: false
            }, this.table)
        },
        //ifShow初始值
        header() {
            let arr = this.table.header;
            for (let index = 0; index < arr.length; index++) {
                if (!arr[index].hasOwnProperty('ifShow')) {
                    this.$set(arr[index], 'ifShow', true)
                }
            }
            return arr;
        },
        currentPage: {
            get() {
            return this.page
            },
            set(val) {
            this.$emit('update:page', val)
            }
        },
        pageSize: {
            get() {
            return this.size
            },
            set(val) {
            this.$emit('update:size', val)
            }
        },
        sort: {
            get() {
            return ''
            },
            set(val) {
            this.$emit('update:orderBy', val)
            // this.$emit('pagination', {})
            }
        },
        sortIndex: {  // 1升序2降序
            get() {
            return ''
            },
            set(val) {
              this.$emit('update:sortIndex', val)
              this.$emit('pagination', {})
            }
        }
    },
    data() {
        return {
            inputPageNum:1,
            tableHeader:[]
        };
    },
    watch:{
        total:{
            handler(newVal){
                if(newVal > 0) {
                    let overPageIndex = Math.ceil(newVal / this.pageSize);
                    if(this.currentPage > overPageIndex) {
                        this.currentPage = overPageIndex;
                        this.inputPageNum = overPageIndex;
                    } else {
                        this.currentPage = this.page;
                    }
                } else {
                    //初始状态
                    this.currentPage = 1;
                    this.inputPageNum = 1;
                }
                
            }
        }
    },
    created(){
        let that = this;
        
        //是否开启表格自适应配置
        if(this.table.resizeConfig) {
            //深拷贝数据   宽度恢复的时候显示
            that.tableHeader = JSON.parse(JSON.stringify(this.table.header));
            //绑定窗口缩放事件
            that.resetTableCell();
            window.addEventListener('resize',that.resetTableCell );
        } else {
            //移除事件
            window.removeEventListener('resize',that.resetTableCell );
        }
    },
    beforeDestroy(){
        if(this.table.resizeConfig) {
            //移除事件
            window.removeEventListener('resize',this.resetTableCell );
        }
    },
    methods: {
        handlePaste(){},
        /**
         * 分页文本框失焦点
         */
        inputBlur(){
            var ex = /^\d+$/;
            if (!ex.test( this.inputPageNum)) {
                this.inputPageNum = 1
                return false;
            } else {
                this.inputPageNum ? this.inputPageNum = this.inputPageNum : this.inputPageNum = 1
            }
            
        },
        /**
         *  分页文本框文本框输入
         */
        handlePageNum(){
            if (!this.inputPageNum) {
                this.inputPageNum = 0;
            }
        },
      rowClassName({
        row,
        rowIndex
      }) {
        row.index = rowIndex; //把每一行的索引放进row
      },
      /**
       * 选择项发生变化时会触发该事件
       * @param {Array} selection 选择项
       */
      selectionChange(selection) {
        this.$emit('changeSelect', selection);
      },

      /**
       * 勾选全选 Checkbox 时触发的事件
       * @param {Array} selection 选择项
       */
      selectAll(selection) {
        this.$emit('selectAll', selection);
      },

      /**
       * 勾选数据行的 Checkbox 时触发的事件
       * @param {Array} selection 选择项
       * @param {Object} row 当前行数据
       */
      select(selection, row) {
        this.$emit('select', selection, row);
      },

      /**
       * 点击当前行事件
       * @param {Object} row 当前行数据
       */
      rowClick(row, column, event) {
        this.$emit('rowClick', row, column, event);
      },
      
        /**
         * 点击某一行表头事件
         * @param {Object} row 当前行数据
         */
        headerClick( column, event) {
            // console.log(column, column)
            if(column.type == "selection"){
                this.$refs.elTable.toggleAllSelection();
            }
        },   
        /**
       * 点击单元格事件
       * @param {Object} row 当前行数据
       */
      cellClick(row, column, event) {
            //点击selection勾选框
            if(column.type == "selection"){
                //而且是未禁用状态
                if(row.recheckStatus == 2){
                    return
                }else{
                    this.$refs.elTable.toggleRowSelection(row);
                }
            }
      },
      /**
       * 新增表格行
       */
      addTable() {
        this.$emit('addTable');
      },
      handleSizeChange(val) {
        // let overPageIndex = Math.ceil(this.total / val);
        // if(val > overPageIndex) {
        //     this.currentPage = overPageIndex;
        //     this.inputPageNum = overPageIndex;
        // } else {
        //     this.currentPage = val;
        // }
        // this.$emit('pagination', {
        //   page: this.currentPage,
        //   size: val
        // })
        this.$emit('pagination', {
          page: 1,
          size: val
        })
      },
      handleCurrentChange(val) {
          let overPageIndex = Math.ceil(this.total / this.pageSize);
          if(val > overPageIndex) {
              this.currentPage = overPageIndex;
              this.inputPageNum = overPageIndex;
          } else {
              this.currentPage = val;
          }
          this.$emit('pagination', {
            page: val,
            size: this.pageSize
        })
      },
      /** 单元格样式 */
      cellStyle(row, column, rowIndex, columnIndex) {
        // console.log(row, column, rowIndex, columnIndex,111);
      },
      /** 表格序号递增 */
      indexUp(index) {
        return (index + 1) + (this.currentPage - 1) * this.pageSize;
      },
      // 从后台获取数据,重新排序
      changeSort(val) {
        if (val.order == 'descending') {
          if (val.prop == 'organizationName') { // 特殊处理
            this.sort = 'organizationId';
          } else {
            this.sort = val.prop;
          }
          this.sortIndex = 2;
        } else if (val.order == 'ascending') {
          if (val.prop == 'organizationName') {
            this.sort = 'organizationId';
          } else {
            this.sort = val.prop;
          }
          this.sortIndex = 1;
        } else {
          this.sort = '';
          this.sortIndex = '';
        }
      },
        //表格单元格设置
        resetTableCell(){
            var browserWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if(browserWidth <= 1366){
                for (let index = 0; index < this.tableHeader.length; index++) {
                    this.table.header[index].width = this.tableHeader[index].resizeWidth || this.tableHeader[index].width;
                }
            } else {
                for (let index1 = 0; index1 < this.tableHeader.length; index1++) {
                    this.table.header[index1].width = this.tableHeader[index1].width;
                }
            }
        },
    },
  };

</script>

<style lang="scss" scoped>
  .table-components {
    .table-operate {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 32px;
      .operate {
        display: flex;
        .operate-btn {
          margin-right: 12px;
        }
      }
    }
    .last {
      display: flex;
      width: 100%;
      height: 50px;
      border: 1px solid #EBEEF5;
      border-top: 0;
      color: #909399;
      box-sizing: border-box;
    }

    .last-title {
      width: 55px;
      height: 50px;
      border-right: 1px solid #EBEEF5;
      font-size: 14px;
      line-height: 50px;
      text-align: center;
    }

    .last-content {
      height: 50px;
      margin-left: 50px;
      font-size: 14px;
      line-height: 50px;
    }

    .pagination {
      padding-top: 44px;
      text-align: center;
      .total-box {
        position: absolute;
        float: left;
        height: 30px;
        line-height: 30px;
        color: #1D2944;

        .total {
          font-size: 20px;
          margin: 0 5px;
        }
      }
    }

    .two-btn {
      margin-top: 5px;
    }
  }

</style>
<style lang="scss">
    .el-table__empty-text{
        line-height: 1!important;
    }
    .table-components {
        .el-switch {
            height: 24px;
            line-height: 24px;
        }
        // 修改表格头样式
        .el-table__header-wrapper,.el-table__fixed-right {
            flex: 0 0 48px; // 占比
            .el-table__header {
                th {
                    background-color: #F7FAFC ;
                    color: #262626;
                    font-size: 14px;
                    font-weight: bold !important;
                }
            } 
        }
        .el-table__fixed-right .cell{
            padding-left: 24px;
            padding-right: 24px;
        }
        //设置行高
        .el-table__header tr,
        .el-table__header th {
            padding: 0;
            height: 56px;
            .cell {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
        .el-table__header th:hover {
            background-color: #F2F5F7;
        }
        .el-table__body tr,
        .el-table__body td {
            padding: 0;
            height: 48px;
            font-size: 14px;
            color: #595959;
            .cell {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }
    .remove-header-bg {
        .el-table__header-wrapper .el-table__header th {
            background-color: transparent;
        }
    }

    //分页样式
    .pagination {
        .el-pagination {
            padding: 0;
        }
        //修改页码的样式
        .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
            background-color: #fff;
            border-radius: 4px;
            border: 1px solid #D9D9D9;
            font-size: 14px;
            color: #595959;
            font-weight: 500;
            min-width: 32px;
            height: 32px;
            line-height: 32px;
        }
        //修改翻页箭头的样式
        .el-pagination .btn-next .el-icon, .el-pagination .btn-prev .el-icon {
            color: #BFBFBF;
        }
        //修改页码被点击后的样式
        .el-pagination.is-background .el-pager li:not(.disabled).active {
            background-color:rgba(50, 112, 255, 1);
            border: 1px solid rgba(50, 112, 255, 1);
        }
        //页码hover后的样式
        .el-pagination.is-background .el-pager li:not(.disabled):hover {
            color: #595959;
            background-color: #F7FAFC;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
            background-color:rgba(50, 112, 255, 1);
            color: #FFF;
        }
        //修改输入框的样式
        .el-pagination .el-input__inner {
            height: 32px;
        }
        .el-pagination .page-input {
            width: 48px;
            margin: 0 8px;
        }
        .el-pagination__sizes {
            margin: 0 0 0 35px;
        }
        .slot-page {
            font-size:14px;
            font-weight:500;
            color:#595959;
            .btn {
                display:inline-block;
                margin-left: 24px;
                line-height:32px;
                color:#FFF;
                font-weight:500;
                width: 76px;
                height: 32px;
                background: #3270FF;
                border-radius: 4px;
                cursor: pointer;
            }
        }
        
    }
    
  .config-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    p {
      width: 140px;
      margin-bottom: 0px;
    }
  }
    .config .el-popover__reference .svg-icon {
        vertical-align: -0.25em;
    }
</style>
