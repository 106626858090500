import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/Layout'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: function () {
            return import('../views/Login.vue')
        },
        meta:{title:'登录'}
    },
    {
        path: '/',
        component: Layout,
        hidden: true,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'Home',
                component: resolve => require(['@/views/Home.vue'],resolve),
                meta:{title:'首页'}
            },
            {
                path: '/organizationManagement',
                name: 'organizationManagement',
                component: resolve => require(['@/views/organization-management/index.vue'],resolve),
                meta:{title:'机构管理'}
            },
            {
                path: '/userManagement',
                name: 'userManagement',
                component: resolve => require(['@/views/user-management/index.vue'],resolve),
                meta:{title:'用户管理'}
            },
            {
                path: '/roleManagement',
                name: 'roleManagement',
                component: resolve => require(['@/views/role-management/index.vue'],resolve),
                meta:{title:'角色管理'}
            },
            {
                path: '/fenceManagement',
                name: 'fenceManagement',
                component: resolve => require(['@/views/fence-management/index.vue'],resolve),
                meta:{title:'围栏'}
            },
            {
                path: '/monitorTrack',
                name: 'monitorTrack',
                component: resolve => require(['@/views/monitor-track/index.vue'],resolve),
                meta:{title:'监控轨迹'}
            },
            {
                path: '/monitorPosition',
                name: 'monitorPosition',
                component: resolve => require(['@/views/monitor-position/index.vue'],resolve),
                meta:{title:'监控定位'}
            },
            {
                path: '/equipmentManagement',
                name: 'equipmentManagement',
                component: resolve => require(['@/views/equipment-management/index.vue'],resolve),
                meta:{title:'设备管理'}
            },
            {
                path: '/alarmStatistics',
                name: 'alarmStatistics',
                component: resolve => require(['@/views/alarm-management/alarmStatistics.vue'],resolve),
                meta:{title:'告警统计'}
            },
            {
                path: '/alarmManagement',
                name: 'alarmManagement',
                component: resolve => require(['@/views/alarm-management/alarmDetails.vue'],resolve),
                meta:{title:'告警详情'}
            },
            {
                path: '/instructionSendingRecord',
                name: 'instructionSendingRecord',
                component: resolve => require(['@/views/instruction-configuration/instructionSendingRecord.vue'],resolve),
                meta:{title:'指令发送记录'}
            },
            {
                path: '/addInstruction',
                name: 'addInstruction',
                component: resolve => require(['@/views/instruction-configuration/addInstruction.vue'],resolve),
                meta:{title:'添加指令'}
            },
            {
                path: '/modelManagement',  
                name: 'modelManagement',
                component: resolve => require(['@/views/instruction-configuration/modelManagement.vue'],resolve),
                meta:{title:'机型管理'}
            },
            {
                path: '/orderManagement',
                name: 'orderManagement',
                component: resolve => require(['@/views/orderManagement/index.vue'],resolve),
                meta:{title:'订单管理'}
            },
            {
                path: '/callStatistics',
                name: 'callStatistics',
                component: resolve => require(['@/views/callStatistics/index.vue'],resolve),
                meta:{title:'呼叫统计'}
            },
            {
                path: '/QRCodeManagement',
                name: 'QRCodeManagement',
                component: resolve => require(['@/views/QRCodeManagement/index.vue'],resolve),
                meta:{title:'挪车码管理'}
            },{
                path: '/advertisingManagement',
                name: 'advertisingManagement',
                component: resolve => require(['@/views/advertisingManagement/index.vue'],resolve),
                meta:{title:'广告管理'}
            },
        ]
    },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
