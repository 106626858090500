import { render, staticRenderFns } from "./Appmain.vue?vue&type=template&id=197ba0a0&scoped=true&"
import script from "./Appmain.vue?vue&type=script&lang=js&"
export * from "./Appmain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "197ba0a0",
  null
  
)

export default component.exports