import request from '@/utils/request'

//获取用户列表
export function listPageUser(data) {
    return request({
        url: '/rbac/user/listPageUser',//请求路径
        method: 'get',
        params: data
    });
}
//获取查询所有角色信息
export function listAllRole(data) {
    return request({
        url: '/rbac/role/listOwnedRole',//请求路径
        method: 'get',
        params: data
    });
}
//获取查询所有角色信息
export function addUserAndRole(data) {
    return request({
        url: '/rbac/user/addUserAndRole',//请求路径
        method: 'post',
        data
    });
}
//修改用户信息 
export function modifyUserAndRole(data) {
    return request({
        url: '/rbac/user/modifyUserAndRole',//请求路径
        method: 'post',
        data
    });
}
//获取对应用户的信息
export function getUserAndRole(data) {
    return request({
        url: '/rbac/user/getUserAndRole',//请求路径
        method: 'get',
        params:data
    });
}
//获取对应用户的信息
export function modifyUserPassword(data) {
    return request({
        url: '/rbac/user/modifyUserPassword',//请求路径
        method: 'post',
        data
    });
}
//修改用户状态
export function modifyUser(data) {
    return request({
        url: '/rbac/user/modifyUser',//请求路径
        method: 'post',
        data
    });
}


