<template>
    <div class="sidebar">
        <el-menu class="sidebar-el-menu" 
                :background-color="variables.menuBg"
                :text-color=" collapse ?  variables.menuTextClose :variables.menuText"
                :active-text-color="variables.menuActiveText"
                router 
                :default-active="$route.path" 
                collapse-transition
                :collapse="collapse">
            <!-- 只有一级菜单的情况 -->
            <template v-for="(item,index) in routes">
                <template v-if="item.children == undefined">
                    <el-menu-item :index="item.path">
                        <svg-icon class="svg-icon" :icon-class="item.icon" size="14"></svg-icon>
                        <span>{{item.name}}</span>
                    </el-menu-item>
                </template>
                <!-- 有多级菜单的情况 -->
                <el-submenu v-if="item.children && item.children.length > 0" :index="item.name">
                    <template slot="title">
                         <svg-icon class="svg-icon" :icon-class="item.icon" size="14"></svg-icon>
                        <span>{{item.name}}</span>
                    </template>
                    <template v-for="child in item.children">
                        <sidebar-item class="nest-menu" v-if="child.children && child.children.length > 0"
                            :routes='[child]'>
                        </sidebar-item>
                        <div v-else>
                            <el-menu-item :index="child.path">
                                <svg-icon class="svg-icon" :icon-class="child.icon" size="14"></svg-icon>
                                <span>{{child.name}}</span>
                            </el-menu-item>
                        </div>
                    </template>
                </el-submenu>
            </template>
        </el-menu>
    </div>
</template>

<script>
    import bus from '@/utils/bus'
    import variables from '@/style/variables.scss'
    export default {
        name: 'SidebarItem',
        props: {
            routes: {
                type: Array
            }
        },
        computed:{
            variables() {
                return variables
            },
        },
        created(){
            // 通过 Event Bus 进行组件间通信，来折叠侧边栏
            bus.$on('collapse', msg => {
                this.collapse = msg;
                bus.$emit('collapse-content', msg);
            });
        },
        data(){
            return {
                collapse: false,
            }
        },
    }
</script>
<style lang="scss" scoped>
    .sidebar {
        overflow-y: scroll;
        max-height: calc(100vh - 156px);
        .el-menu {
            height: auto;
            min-height: 100%;
            border-right: 0;
        }
        scrollbar-color: transparent transparent;
        scrollbar-track-color: transparent;
        -ms-scrollbar-track-color: transparent;
    }
    .sidebar::-webkit-scrollbar {
        width:0px;
        height:0px;
        scrollbar-width: thin;
    }
    .sidebar-el-menu:not(.el-menu--collapse) {
        width: 208px;
    }
    .sidebar > ul {
        height: 100%;
    }
    .svg-icon {
        margin-right: 10px;
    }
    
</style>