<template>
    <div class="add-modify-user">
        <el-dialog 
            title="修改密码"
            :visible.sync="show"
            :close-on-click-modal="false"
            width="424px">
            <el-form :model="modifyPassword" status-icon :rules="rules" ref="modifyPassword" label-width="68px" class="demo-ruleForm">
                <el-form-item label="旧密码" prop="oldPassword">
                    <el-input type="password" placeholder="请输入旧密码" class="input-w290-h32" v-model="modifyPassword.oldPassword" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPassword">
                    <el-input type="password" placeholder="请输入新密码" class="input-w290-h32" v-model="modifyPassword.newPassword" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="checkNewPassword">
                    <el-input type="password" placeholder="请输入确认密码" class="input-w290-h32" v-model="modifyPassword.checkNewPassword" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <tem-btn  type="info" @click="show = false"  value="取消"></tem-btn>
                <tem-btn  type="primary" @click="submitData('modifyPassword')"  value="确定"></tem-btn>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import popover  from '@/components/popover/index'
export default {
    model: {
        prop: 'modifyPasswordDialog',
        event: 'passEvent'
    },
    components:{
        popover
    },
    props:{
        modifyPasswordDialog:{
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default:'add'
        },
        modifyPassword:{
            type: Object,
            default:() => {
                return {
                    oldPassword:'',
                    newPassword:'',
                    checkNewPassword:''
                }
            }
        },
        roleList:{
            type: Array,
            default:() => {
                return []
            }
        },
        treeData:{
            type: Array,
            default:() => {
                return []
            }
        },
    },
    computed:{
        comTitle(){
            return this.title == 'add' ? '新增用户' : '编辑用户'
        }
    },
    data(){
      var validatePass = (rule, value, callback) => {
        const reg = /(?!.*\s)(?!^[\u4e00-\u9fa5]+$)(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{6,16}$/;
        if (value === '') {
          callback(new Error('请输入密码'));
        } 
        else if(!reg.test(value)){
            callback(new Error('包含数字,字母或字符至少两种,长度8-16个字符'));
        }else {
          if (this.modifyPassword.checkNewPassword !== '') {
            this.$refs.modifyPassword.validateField('checkNewPassword');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        const reg2 = /(?!.*\s)(?!^[\u4e00-\u9fa5]+$)(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{6,16}$/;
        if (value === '') {
          callback(new Error('请再次输入密码'));
        }
        else if (!reg2.test(value)) {
            callback(new Error('包含数字,字母或字符至少两种,长度8-16个字符'));
            // 判断两次密码是否输入一样
        }else if (value !== this.modifyPassword.newPassword) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
        return {
            show: this.modifyPasswordDialog,
            rules:{
                oldPassword:[
                    { required: true, message:'请输入原始密码',trigger:'change'}
                ],
                newPassword:[
                    { required: true,validator: validatePass, trigger: 'change' }
                    ],
                checkNewPassword: [
                    { required: true,validator: validatePass2, trigger: 'change' }
                    ],
            },
        }
    },
    watch: {
        //对父组件传来的地址进行监听
        show:{
            handler(newVal, oldVal) {
                this.$emit('passEvent',newVal);
                if(!newVal) {
                    this.$refs.modifyPassword.resetFields();
                }
            },
            deep: true,
        },
        modifyPasswordDialog:{
            handler(newVal, oldVal) {
                this.show = newVal;
            },
            deep: true,
        },
    },
    methods:{
        submitData(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$emit('submitData',this.modifyPassword);
                }
            });
        },
    }
}
</script>