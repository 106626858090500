import {login} from '@/api/user'
import {getToken,setToken,removeToken,getUsername,setUsername,removeUsername,getOrgId,setOrgId,getOrgName,setOrgName,setOrgCode,getOrgCode} from '@/utils/cookie'
import router from '@/router'

const state = {
    token: getToken(),
    username: getUsername(),
    orgId: getOrgId(),
    orgName: getOrgName(),
    orgCode: getOrgCode()
}
const mutations = {
    SET_TOKEN:(state, token) => {
        state.token = token
    },
    SET_USERNAME:(state, username) => {
        state.username = username
    },
    SET_ORGNAME:(state, orgName) => {
        state.orgName = orgName
    },
    SET_ORGID:(state, orgId) => {
        state.orgId = orgId
    },
    SET_ORGCODE:(state, orgCode) => {
        state.orgCode = orgCode
    },
}

const actions = {
    //用户登录
    login({ commit },userInfo){
        const {username,password} = userInfo;
        return new Promise((resolve,reject) => {
            login({ username: username.trim(),password:password }).then(response => {
                const { data } = response
                commit('SET_TOKEN',data.token);
                setToken(data.token);
                commit('SET_USERNAME',data.userName);
                setUsername(data.userName);
                commit('SET_ORGNAME',data.orgName);
                setOrgName(data.orgName);
                commit('SET_ORGID',data.orgId);
                setOrgId(data.orgId);
                commit('SET_ORGCODE',data.orgCode);
                setOrgCode(data.orgCode);
                // debugger
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    //用户退出登录
    logout({ commit, state, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('SET_TOKEN', '')
            removeToken()
            router.push('/login')
            resolve()
        })
    },
}

export default {
    namespaced:true,
    state,
    mutations,
    actions
}
