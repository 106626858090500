<template>
    <div class="sidebar-item">
         <div class="open-or-close" @click="openCloseMenu">
             <img :src="collapse ? openUrl : closeUrl" alt="">
         </div>
        <div class="logo">
            <div :class="collapse ? 'colse-log-img' : 'log-img'">
                <img src="../assets/logo.png" alt="">
            </div>
        </div>
        <sidebar-item sidebar-item :routes="routes"></sidebar-item>
    </div>
</template>

<script>
    import bus from '@/utils/bus'
    import SidebarItem from './SidebarItem.vue'
    import {
        listMenu
    } from '@/api/user'
    import {
        getToken,
        setToken,
        removeToken,
    } from '@/utils/cookie'

    export default {
        name: 'Sidebar',
        components: {
            SidebarItem
        },
        data() {
            return {
                routes: [],//路由数据 生成菜单
                collapse: false,//控制菜单的折叠
                openUrl:require('../assets/image/menu/icon_sidebar_on.png'),//打开菜单图标
                closeUrl:require('../assets/image/menu/icon_sidebar_close.png'),//隐藏菜单图标
            }
        },
        created() {
            //获取菜单数据
            // setTimeout(() => {
                this.fetchMenus()
            // }, 500)
        },
        methods: {
            // 获取菜单
            fetchMenus() {
                const res = [];
                function filterRoutes(routes) {
                    routes.forEach(route => {
                        const tmp = {...route };
                        if (tmp.children) {
                            filterRoutes(tmp.children);
                        }
                        res.push(tmp.path);
                    });
                    return res;
                }
                //获取token
                let token = getToken();
                listMenu(token).then(res => {
                    this.routes = res.data;
                    let permissionRoutes = filterRoutes(this.routes);
                    this.$store.dispatch('permission/setPermissionRoutes',permissionRoutes);
                })
            },
            //折叠菜单  传值
            openCloseMenu(){
                this.collapse = !this.collapse;
                bus.$emit('collapse', this.collapse);
            }
        }
    }
</script>
<style lang="scss" scoped>
.sidebar-item {
    position: relative;
    .open-or-close {
        position: absolute;
        top: 32px;
        right: 0;
        width: 24px;
        height: 24px;
        cursor:pointer;
        z-index: 5;
        display: none;
        img {
            width: 24px;
            height: 24px;
            object-fit: cover;
        }
    }
    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 156px;
        box-sizing: border-box;
        padding-top: 2px;
        background-color: #E9F0FF;
        .log-img {
            width: 120px;
            height: 120px;
            img {
                width: 120px;
                height: 120px;
                object-fit: cover;
            }
        }
        .colse-log-img {
            width: 60px;
            height: 60px;
            img {
                width: 60px;
                height: 60px;
                object-fit: cover;
            }
        }
    }
}
.sidebar-item:hover .open-or-close {
    display: block !important ;
} 

</style>