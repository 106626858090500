import request from '@/utils/request';
//获取警告统计列表
export function alarmSummary(data) {
    return request({
        url: '/alarm/pageAlarmSummaryExt',//请求路径
        method: 'post',
        data
    });
}
//通过组织机构ID 获取用户列表
export function getUserListByOrganizationId(data) {
    return request({
        url: '/alarm/user',//请求路径
        method: 'post',
        data,
    });
}
//通过组织机构ID 获取告警列表
export function alarmList(data) {
    return request({
        url: '/alarm/alarmCheck',//请求路径
        method: 'get',
        params: data,
    });
}
//通过组织机构ID 全部已读告警列表
export function alarmRead(data) {
    return request({
        url: '/alarm/alarmRead',//请求路径
        method: 'get',
        params: data,
    });
}