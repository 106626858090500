
<template>
	<div class="app-wrapper" :class="classObj">
        <!-- <div> -->
            <sidebar :class=" collapse ? 'sidebar-container-hidden': 'sidebar-container'"></sidebar>
        <!-- </div> -->
        <div class="main-container">
            <navbar class="nav-bar"></navbar>
            <app-main class="app-main"></app-main>
            <!-- 小铃铛 -->
            <!-- <div class="alart-box" @click="alartDialogVisible = true">
                <div class="point" v-if="showRedPoint"></div>
                <svg-icon class="svg-icon icon-link" icon-class="icon_notification" size="32"></svg-icon>
            </div> -->
        </div>
        <alart-news v-model="alartDialogVisible"></alart-news>
    </div>
</template>

<script>
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import appMain from './Appmain'
import bus from '@/utils/bus'
import alartNews from '@/views/alarm-management/alartNews'
import { alarmList } from '@/api/statisticalReport/alarmManagement';

export default {
  name: 'Layout',
  components: {
    Sidebar,
    Navbar,
    appMain,
    alartNews
    },
    data(){
        return {
            collapse:false,
            alartDialogVisible: false, // 告警消息弹窗
            //搜索栏数据
            listQuery:{
                fullUserId: this.$store.state.user.orgCode, // 组织id
                pageIndex: 1,//当前查询页面
                pageSize: 10,//每页数据数量
            },
            showRedPoint: false,  // 是否显示有消息的红点
        }
    },
	computed: {
        classObj() {
            return {
                hideSidebar: this.collapse,
                openSidebar: !this.collapse,
            }
        }
    },
    created(){
        // 通过 Event Bus 进行组件间通信，来折叠侧边栏
        bus.$on('collapse', msg => {
            this.collapse = msg;
            bus.$emit('collapse-content', msg);
        });
    },
    mounted() {
        // this.getAlarmList();
        // window.alartInterval = setInterval(() => {
        //     this.getAlarmList();
        // }, 60000)
    },
    methods: {
        /** 获取告警列表 */
        getAlarmList() {
            alarmList(this.listQuery).then(res => {
                if (res.code == 200 && res.data.length > 0) {
                    this.showRedPoint = true;
                } else if (res.code == 200 && res.data.length == 0) {
                    this.showRedPoint = false;
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
	.app-wrapper {
        display: flex;
		height: 100%;
        width: 100%;
        max-width: 100%;
        .sidebar-container {
            background-color: #E9F0FF;
            background-image: url('../assets/image/menu/bg_sidebar.png');
            background-repeat: no-repeat;
            background-position: bottom center;
            background-size: 208px 208px;
        }
		.main-container {
            position: relative;
            flex: 1;
            overflow: auto;
            .app-main {
                padding: 24px;
                height: 100%;
                width: 100%;
                max-height: calc(100vh - 56px);
                box-sizing: border-box;
                background-color: #F7F8FA;
                overflow: auto;
            }
            .alart-box {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 50%;
                right: 8px;
                width: 56px;
                height: 56px;
                border-radius: 50%;
                background: #3270FF;
                box-shadow: 0px 9px 28px 8px rgba(50, 112, 255, 0.06), 0px 6px 16px 0px rgba(50, 112, 255, 0.1), 0px 3px 6px -4px rgba(50, 112, 255, 0.24);
                cursor: pointer;
                z-index: 999;
                .point {
                    position: absolute;
                    top: 13px;
                    right: 13px;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: #EE3932;
                }
            }
		}
        //收起菜单后  背景图隐藏
        .sidebar-container-hidden {
            background-color: #E9F0FF;
        }
	}
</style>
