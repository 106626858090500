<template>
    <div class="organization-tree">
        <el-popover
            placement="bottom" 
            width="292" :popper-class="'organization-popover open-popver ' + addNewClass"
            v-model="show"
            trigger="click" @hide="popperHide('hide')" @show="popperHide('show')" :disabled="isDisabled">
                <div class="search-top"></div>
                <div class="search-input">   
                    <el-input class="input-w268-h32"
                        v-model="filterVal"   :validate-event="false" >
                    </el-input>
                    <svg-icon class="svg-icon icon-search" icon-class="icon-search" size="16"></svg-icon>
                </div>
                <el-tree v-if="isReset" ref="organizationTree" :data="data"  :expand-on-click-node="false"
                :default-expanded-keys="defaultCheck" :props="defaultProps" node-key="orgId" 
                highlight-current :filter-node-method="filterNode" @node-click="nodeClick">
                </el-tree>
                <slot slot="reference" name="treeReference">
                </slot> 
        </el-popover>
    </div>
    
</template>

<script>
import { listTreeOrg } from '@/api/organization-management' 
export default {
  name: '',
  model: {
        prop: 'popoverShow',
        event: 'popoverEvent'
    },
  props:{
       popoverShow:{
            type: Boolean,
            default: false
        },
        data:{
            type:Array
        },                                                                                    
        //是否禁止弹框
        isDisabled:{
            type:Boolean,
            default:false
        },
        //新增类名
        addNewClass:{
            type: String,
            default:''
        },
        // 是否重置树
        isReset:{
            type:Boolean,
            default: true
        }
  },
  data() {
        return {
            orgNameVal:'',
            filterVal: '',//过滤
            treeData:[],
            defaultCheck:[],
            defaultProps: {
                children: 'children',
                label: 'orgName'
            },
            show: this.popoverShow,
            treeClickCount:0
        }
  },

  methods:{
        popperHide(val){
            this.filterVal = '';
            this.$emit(val);
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.orgName.indexOf(value) !== -1;
         },
         nodeClick(val){
            //记录点击次数
            this.treeClickCount++;
            //单次点击次数超过2次不作处理,直接返回,也可以拓展成多击事件
            if (this.treeClickCount >= 2) {
                return;
            }
            //计时器,计算300毫秒为单位,可自行修改
            this.timer = window.setTimeout(() => {
                if (this.treeClickCount == 1) {
                    //把次数归零
                    this.treeClickCount = 0;
                    //单击事件处理
                    this.$emit('nodeClick',val,'single');
                } else if (this.treeClickCount > 1) {
                    //把次数归零
                    this.treeClickCount = 0;
                    this.$emit('nodeClick',val,'db')
                }
            }, 300);
        }
    },
   watch: {
        filterVal(val){
            this.$refs.organizationTree.filter(val);			
        },
        //对父组件传来的地址进行监听
        show:{
            handler(newVal, oldVal) {
                this.$emit('popoverEvent',newVal);
            },
            deep: true,
        },
        popoverShow:{
            handler(newVal, oldVal) {
                this.show = newVal;
            },
            deep: true,
        },
    },
}
</script>

<style lang="scss" scope>
    .organization-tree .pop-close-style:focus {
        outline: none;
    }
    .organization-tree .el-popover__reference{
        display: inline-block;
        text-align: center;
        padding: 0px 0px;
        cursor: pointer;
    }
    .organization-tree .el-popover__reference .el-icon-arrow-down,.organization-name,
    .organization-tree .el-icon-my-cluster{
        vertical-align: middle;
    }
    .organization-active{
        transform: rotate(180deg);
    }
    .organization-name{
        display: inline-block;
        max-width: 230px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .organization-popover{
        padding:0px 12px 8px 12px !important;
        height:268px !important;
        // overflow: auto;
        margin-top: 0px !important;
        box-sizing: border-box;
        // box-shadow: none !important;
        box-shadow:  0px 9px 10px 8px rgba(0, 0, 0, 0.02), 0px 6px 8px 0px rgba(0, 0, 0, 0.04), 0px 3px 4px -4px rgba(0, 0, 0, 0.08) !important;
        border-top: 1px solid #F0F0F0 !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        .search-input {
            position: relative;
            .el-input__inner {
                padding-right: 30px;
                border: 1px solid #D9D9D9;
            }
        }
        .search-top{
            width:100%;
            height:1px;
            background-color:#F0F0F0;
            margin-bottom:8px;
        }
        .icon-search {
            position:absolute;
            top:8px;
            right:8px;
        }
        .el-tree {
            overflow: auto;
            height: 196px;

        }
        .el-popper .popper__arrow {
            display: none !important;
        }
    }
    .open-popver {
        border:1px solid  #3270FF !important;
        border-top:none !important;
        .tree-content {
            border-top: 1px solid #F0F0F0;
        }
    }
    .organization-popover .el-input{
        margin-bottom:20px;
    }
    .el-tree-node__content:hover{
        background-color: rgba(50, 112, 255, 0.1);
    }
    .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
        background-color: rgba(50, 112, 255, 0.1);
        color: #3270FF;
        display: block;
        width: 100%;
        line-height: 26px;
    }
    .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content:hover {
        background-color: rgba(50, 112, 255, 0.1);
    }
    .organization-tree .el-tree{
        overflow: auto;
        // height: 195px;
        height: 395px;
    }
    .el-tree-node>.el-tree-node__children{
        overflow: visible;
    }
    .el-tree-node__expand-icon{
        font-size: 14px;
    }
</style>
<style lang="scss">
.organization-tree {
    height: 32px;
    .org-input {
        position: relative;
        height:32px ;
        width:292px; 
        box-sizing:border-box;
        text-align: left;
        // text-indent: 15px;
        .icon-link {
            position: absolute;
            top: 8px;
            right: 12px;
        }
        .el-input.is-disabled .el-input__inner {
            background-color:#FFFFFF;
            color: #606266 !important;
            cursor: pointer;
        }
    }
    .pop-close-style {
        box-sizing:border-box;
        background-color:#FFFFFF;
        .el-input.is-active .el-input__inner, .el-input__inner:focus {
            border-color:  #D9D9D9;
        }
        .el-input__inner {
            border-radius: 4px;
            border: 1px solid #D9D9D9;
        }
    }
    .pop-open-style {
        box-sizing:border-box;
        
        .el-input.is-active .el-input__inner, .el-input__inner:focus {
            border-color:  #D9D9D9;
        }
        .el-input__inner {
            border-radius: 4px 4px 0 0 !important;
            border:1px solid  #3270FF !important;
            border-bottom:none !important;
        }
    }
    .org-container {
        display: flex;
        align-items: center;
    }
    .svg-icon {
        margin-left: 2px;
        transition: all .3s;
    }
    .icon-transform {
        transform: rotate(180deg);
    }
}
.organization-popover.el-popper .popper__arrow {
    display: none;
}
</style>