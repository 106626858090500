<template>
    <div class="header">
        <div class="header-right">
            <div class="header-user-con">
                <!-- 用户名下拉菜单 -->
                <el-dropdown class="user-name" trigger="click" @command="handleCommand" placement="bottom">
                    <div class="el-dropdown-link">
                        <span class="user-name">{{username}}</span>
                        <svg-icon class="svg-icon icon-link" icon-class="icon-down" size="16"></svg-icon>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item  command="modifyPas">
                            <div class="dropdown-item">
                                <svg-icon class="svg-icon icon-link" icon-class="icon_modify_password" size="16"></svg-icon>
                                <span class="word user-modify-pas">修改密码</span>
                            </div>
                        </el-dropdown-item>
                        <el-dropdown-item  command="loginout">
                            <div class="dropdown-item">
                                <svg-icon class="svg-icon icon-link" icon-class="icon_loginout" size="16"></svg-icon>
                                <span class="word user-loginout">退出登录</span>
                            </div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <modify-password v-model="modifyPassDialog" :modifyPassword="modifyPassword" @submitData="submitData"/>
    </div>
</template>
<script>
import bus from '../utils/bus';
import { getUsername ,removeUsername} from '@/utils/cookie'
import modifyPassword from '@/views/modify-password/index'
import { modifyUserPassword } from '@/api/user-management/index'
export default {
    components:{
        modifyPassword
    },
    data() {
        return {
            fullscreen: false,
            name: 'jimi',
            message: 0,
            modifyPassDialog: false,
            modifyPassword:{
                oldPassword:'',
                newPassword:'',
                checkNewPassword:''
            }
        };
    },
    computed: {
        username() {
            let username = getUsername();
            return username ? username : this.name;
        }
    },
    methods: {
        // 用户名下拉菜单选择事件
        handleCommand(command) {
            if (command == 'loginout') {
                // removeUsername();
                clearInterval(window.alartInterval);
                this.$store.dispatch('user/logout');
                this.$store.dispatch('permission/clearRoute');
            } else if( command == 'modifyPas' ) {
                this.modifyPassDialog = true;
            }
        },
        //修改密码提交
        submitData(val){
            //密码加密
            let modifyPassword = JSON.parse(JSON.stringify( val ));
            modifyPassword.oldPassword = this.RSA.getRsaCode(modifyPassword.oldPassword);
            modifyPassword.newPassword = this.RSA.getRsaCode(modifyPassword.newPassword);
            modifyPassword.checkNewPassword = this.RSA.getRsaCode(modifyPassword.checkNewPassword);
            modifyUserPassword(modifyPassword).then( res => {
                if(res.code == 200) {
                    this.modifyPassDialog = false;
                    this.$message({type:'success',message:'修改成功'});
                }
            })
        },
    },
};
</script>
<style lang="scss"  scoped>
.header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    font-size: 22px;
    color: #fff;
    background-color: #fff;
}
.header .logo {
    float: left;
    width: 250px;
    line-height: 70px;
}
.header-right {
    float: right;
    padding-right: 36px;
}
.header-user-con {
    display: flex;
    height: 56px;
    align-items: center;
}

.user-name {
    display:inline-block;
    font-size: 16px;
    color: #262626;
    margin-right:8px;
}
.el-dropdown-link {
    cursor: pointer;
    outline: none;
}
.user-avator {
    margin-left: 20px;
}
.user-avator img {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.el-dropdown-menu__item {
    text-align: center;
}
.el-dropdown-menu {
    padding: 8px 0;
    background: #FFFFFF;
    box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px 0px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    border-width: 0;
}
.el-dropdown-menu__item--divided{
    border-width: 0;
    margin-top: 0;
}
.el-dropdown-menu__item {
    line-height: auto;
    padding: 0 12px 0 12px;
}
.el-dropdown-menu__item--divided:before {
    height: 0px;
}

.word {
    display: inline-block;
    margin-left: 8px;
    font-size: 14px;
    color: #595959;
    line-height: 20px;
}
.el-dropdown-menu__item:focus , .el-dropdown-menu__item:not(.is-disabled):hover{
    background-color: #F7FAFC;
}
.el-dropdown-menu__item:focus , .el-dropdown-menu__item:not(.is-disabled):hover .word{
    color: #3270FF;
}
.dropdown-item {
    display: flex;
    height: 32px;
    align-items: center;
}
.dropdown-item:hover {
    color:#3270FF
}
</style>
