<template>
    <div class="alart-news">
        <el-dialog
            :visible.sync="show"
            :close-on-click-modal="false"
            width="816px">
            <div slot="title" class="dialog-title">
                <div class="title-div">
                    <span class="title">消息中心</span>
                    <span class="tag">提示：同一设备不同告警类型只显示最近一条报警记录，点击数量查看更多</span>
                </div>
                <div class="all-read">
                    <el-link type="primary" :underline="false" @click="allRead">全部已读</el-link>
                </div>
            </div>
            <div v-loading="loading">
                <Table :table="table" :total="listQuery.total" :page.sync="listQuery.pageIndex" :size.sync="listQuery.pageSize"
                :orderBy.sync="listQuery.orderBy" @pagination="getAlarmList" :layout="layout"></Table>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Table from '@/components/table/table';
import { alarmList, alarmRead } from '@/api/statisticalReport/alarmManagement';
export default {
    components: {
        Table,
    },
    model: {
        prop: 'alartDialogVisible',
        event: 'userEvent'
    },
    props:{
        alartDialogVisible:{
            type: Boolean,
            default: true
        },
    },
    data(){
        return {
            show: this.alartDialogVisible,
            loading: true,  // 表格转圈圈
            //搜索栏数据
            listQuery:{
                fullUserId: this.$store.state.user.orgCode, // 组织id
                pageIndex: 1,//当前查询页面
                pageSize: 10,//每页数据数量
            },
            layout: 'total, prev, pager, next, slot',
            //表格数据
            table: {
                configItem: false, //是否开启表格项配置
                height: null,
                data: [],
                header: [
                    {
                        prop: 'imei',
                        label: 'IMEI',
                        // minWidth: '180',
                        ifShow: true,
                    },
                    {
                        prop: 'alarmType',
                        label: '报警类型',
                        // minWidth: '120',
                        tooltip: true,
                        ifShow: true,
                        render:(h,param) => {
                            var iconClass = '', alarmType = '';
                            switch (param.alarmType) {  // 不同类型用不同图标
                                case 'INTO_FENCE':
                                    iconClass = 'icon_common_list_ingogray';
                                    alarmType = '进入围栏警告';
                                    break;
                                case 'OUT_OF_FENCE':
                                    iconClass = 'icon_common_list_gogray';
                                    alarmType = '离开围栏警告';
                                    break;
                                case '离线':
                                    iconClass = 'icon_common_list_offgray';
                                    alarmType = '离线警告';
                                    break;
                                case '低电':
                                    iconClass = 'icon_common_list_lowpowergray';
                                    alarmType = '低电警告';
                                    break;
                            
                                default:
                                    iconClass = 'icon_common_list_ingogray';
                                    alarmType = '';
                                    break;
                            }
                            return h('div', [
                                h(
                                    'svg-icon', {
                                        props: {
                                            iconClass: iconClass,
                                            size: 16
                                        },
                                    }
                                ),
                                h(
                                    'span', {
                                        style: {
                                            marginLeft: '6px'
                                        },
                                        props: {
                                            type:'primary',
                                            underline: false
                                        },
                                        on:{
                                            click:()=> {
                                                this.goAlartDetails(param);
                                            }
                                        }
                                    },
                                    alarmType
                                ),
                            ]);
                        }
                    },
                    {
                        prop: 'alarmTime',
                        label: '最近报警时间',
                        sortable: 'column',
                        // minWidth: '180',
                        tooltip: true,
                        ifShow: true,
                    },
                    {
                        prop: 'count',
                        label: '报警数量',
                        sortable: 'column',
                        // minWidth: '180',
                        tooltip: true,
                        ifShow: true,
                        render:(h,param) => {
                            return h('div', [
                                h(
                                    'el-link', {
                                        props: {
                                            type:'primary',
                                            underline: false
                                        },
                                        style: {
                                            color: '#3270FF'
                                        },
                                        on:{
                                            click:()=> {
                                                this.goAlartDetails(param);
                                            }
                                        }
                                    },
                                    param.count
                                ),
                            ]);
                        }
                    },
                ],
            },
        }
    },
    watch: {
        //对父组件传来的地址进行监听
        show:{
            handler(newVal, oldVal) {
                this.$emit('userEvent',newVal);
            },
            deep: true,
        },
        alartDialogVisible:{
            handler(newVal, oldVal) {
                this.show = newVal;
            },
            deep: true,
        },
    },
    mounted() {
        //获取列表数据
        // this.getAlarmList();
    },
    methods:{
        /** 获取告警列表 */
        getAlarmList() {
            alarmList(this.listQuery).then(res => {
                if (res.code == 200) {
                    res.data.length > 6 ? this.table.height = 300 : '';
                    this.table.data = res.data;
                    this.listQuery.total = res.count;
                    this.loading = false;
                }
            })
        },
        /** 全部已读 */
        allRead() {
            alarmRead({fullUserId: this.listQuery.fullUserId}).then(res => {
                if (res.code == 200) {
                    this.getAlarmList();
                }
            })
        },
        /** 跳转告警详情 */
        goAlartDetails(val) {
            this.show = false;
            this.$router.push({name: 'alarmManagement', query: {imei: val.imei}});
        }
    }
}
</script>
<style lang="scss" scoped>
.alart-news {
    
}
</style>
<style lang="scss">
.alart-news {
    .dialog-title {
        width: 95%;
        display: flex;
        justify-content: space-between;
        .title-div {
            display: flex;
            align-items: center;
            .title {
                margin-right: 8px;
                font-size: 20px;
                font-weight: 500;
                color: #262626;
            }
            .tag {
                padding: 0 8px;
                font-size: 12px;
                border: 1px solid #FFD0A5;
                border-radius: 2px;
                background: #FFF8F1;
                color: #595959;
            }
        }
    }
    .all-read {
        .el-link.el-link--primary {
            color: #3270FF;
            .el-link--inner {
                font-weight: 400;
            }
        }
    }
    .el-dialog__body {
        padding: 32px 32px 42px 32px;
    }
}

</style>