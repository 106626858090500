import request from '@/utils/request'

export function login(data) {
    return request({
        url: './login',
        method: 'post',
        data
    })
}

export function logout(data) {
    return request({
        url: './logout',
        method: 'get',
        data
    })
}

//未使用
export function getInfo(token) {
    return request({
        url: '/info',
        method: 'get',
        params: { token }
    })
}

//路由
export function listMenu(token) {
    return request({
        url: './list/menu',
        method: 'get',
        params: { token }
    })
}
