import router from '../router';
import store from '../store';

import { getToken } from '@/utils/cookie';

const whiteList = ['/login'];
router.beforeEach(async(to, from, next) => {
    document.title = `${to.meta.title} | JIMI挪车`;
    const hasToken = getToken();
    if (hasToken) {
        if(store.getters.routes) {
            let route = JSON.parse(JSON.stringify(store.getters.routes));
            if (route.indexOf(to.path) > -1 || to.path === '/login' || to.path === '/home'){
                next();
            } else {
                next(from.path);
                return;
            }
        } else if (to.path === '/home' ) {
            next();
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next();
        } else {
            //没有权限的路由
            next(`/login?redirect=${to.path}`);
        }
    }
});

router.afterEach(() => {
});
