import axios from 'axios'
import { Message } from 'element-ui';
import router from '@/router'
import { getToken , removeToken} from '@/utils/cookie'
import $config from './config' //引入不同的环境地址

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

//创建axios 实例
const service = axios.create({
    //超时
    timeout:30000
});
//区分不同环境地址
switch(process.env.VUE_APP_CURENV){
    case 'test':
        service.defaults.baseURL = $config.apiUrl.test
        break;
    case 'development':
        service.defaults.baseURL = $config.apiUrl.development
        break;
    case 'production':
        service.defaults.baseURL = $config.apiUrl.production
        break;
}

//请求拦截器
service.interceptors.request.use(
    res => {
        res.headers.token = getToken();
        return res;
    },
    error => {
        return Promise.reject(error);
    }
)

//响应拦截器
service.interceptors.response.use(
    res => {
        //未设置状态密码默认成功状态
        const code = res.data.code || 200;
        //获取错误信息
        if(code == 101){
            Message.closeAll();
            Message({showClose: true,message: '系统繁忙',type: 'warning'});
            return Promise.reject('error')
        }else if(code == 100){
            Message.closeAll();
            Message({showClose: true,message: '失败-'+res.data.message,type: 'warning'});
            // return Promise.reject('error')
            return res
        }else if(code == 200){
            return Promise.resolve(res.data)
        }else if(code == 301){
            Message.closeAll();
            Message({showClose: true,message: '流量限流异常',type: 'warning'});
            return Promise.reject('error')
        }else if(code == 311){
            Message.closeAll();
            Message({showClose: true,message: '限流(系统繁忙)',type: 'warning'});
            return Promise.reject('error')
        }else if(code == 321){
            Message.closeAll();
            Message({showClose: true,message: '熔断',type: 'warning'});
            return Promise.reject('error')
        // }else if(code == 4000){
        //     Message.closeAll();
        //     Message({showClose: true,message: '未知错误',type: 'warning'});
        //     return Promise.reject('error')
        // }else if(code == 4001){
        //     Message.closeAll();
        //     Message({showClose: true,message: '非法请求',type: 'warning'});
        //     return Promise.reject('error')
        // }else if(code == 4002){
        //     Message.closeAll();
        //     Message({showClose: true,message: '非法字符',type: 'warning'});
        //     return Promise.reject('error')
        // }else if(code == 4003){
        //     Message.closeAll();
        //     Message({showClose: true,message: '参数错误',type: 'warning'});
        //     return Promise.reject('error')
        // }else if(code == 4004){
        //     Message.closeAll();
        //     Message({showClose: true,message: '状态错误',type: 'warning'});
        //     return Promise.reject('error')
        // }else if(code == 4005){
        //     Message.closeAll();
        //     Message({showClose: true,message: '没有数据',type: 'warning'});
        //     return Promise.reject('error')
        // }else if(code == 4006){
        //     Message.closeAll();
        //     Message({showClose: true,message: '数据一致性错误',type: 'warning'});
        //     return Promise.reject('error')
        // }else if(code == 4007){
        //     Message.closeAll();
        //     Message({showClose: true,message: '不合法的URL',type: 'warning'});
        //     return Promise.reject('error')
        }else if(code == 5000){
            Message.closeAll();
            Message({showClose: true,message: '用户不存在',type: 'warning'});
            return Promise.reject('error')
        }else if(code == 5001){
            Message.closeAll();
            Message({showClose: true,message: '未登录',type: 'warning'});
            return Promise.reject('error')
        }else if(code == 5002){
            Message.closeAll();
            Message({showClose: true,message: 'TOKEN无效',type: 'warning'});
            removeToken();
            router.push('/login')
            return Promise.reject('error')
        }else if(code == 5003){
            Message.closeAll();
            Message({showClose: true,message: 'token异常',type: 'warning'});
            return Promise.reject('error')
        }else if(code == 5004){
            Message.closeAll();
            Message({showClose: true,message: '权限不足',type: 'warning'});
            return Promise.reject('error')
        }else if(code == 5005){
            Message.closeAll();
            Message({showClose: true,message: '名称重复',type: 'warning'});
            return Promise.reject('error')
        }else{
            if(res.data.message == ''){
                res.data.message = "系统异常"
            }
            return res
        }
    },
    error => {
        return Promise.reject(error);
    }
)
export default service;