import request from '@/utils/request'

//获取机构列表
export function listPageOrg(data) {
    return request({
        url: '/rbac/org/listPageOrg',//请求路径
        method: 'get',
        params: data
    });
}
//新增机构
export function addOrg(data) {
    return request({
        url: '/rbac/org/addOrg',//请求路径
        method: 'post',
        data
    });
}
//通过组织id获取组织信息
export function getOneOrgDetail(data) {
    return request({
        url: '/rbac/org/getOneOrgDetail',//请求路径
        method: 'get',
        params: data
    });
}
//修改组织信息
export function modifyOrg(data) {
    return request({
        url: '/rbac/org/modifyOrg',//请求路径
        method: 'post',
        data
    });
}
//修改组织信息
export function listTreeOrg(data) {
    return request({
        url: '/rbac/org/listTreeOrg',//请求路径
        method: 'get',
        params:data
    });
}
