<template>
    <section class="app-main">
        <transition name="fade" mode="out-in">
            <router-view :key="key"></router-view>
        </transition>
    </section>
</template>

<script>
    export default {
        computed: {
            key() {
                return this.$route.label ? this.$route.name + new Date() : this.$route + new Date()
            }
        }
    }
</script>
<style scoped>
</style>