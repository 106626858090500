export default {
    name: 'TableExpand',
    functional: true,
    props: {
        row: Object,
        render: Function,
        index: Number,
        column: {
            type: Object,
            default: null
        }
    },

    /** 
     * 渲染函数
     * @param {Function} h // 渲染方法
     * @param {Object} ctx // 数据
     */
    render: (h, ctx) => {
        const params = ctx.props.row;
        if (ctx.props.column) params.column = ctx.props.column;
        return ctx.props.render(h, params, ctx.props.index);
    }
};