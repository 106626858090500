import JSEncrypt from 'jsencrypt';
 
export default {
    getRsaCode(str) {
        const pubKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC4VJjcqZgiAZORlOsVJ394kgX6
        j1IVn8EGc2OxEluLS2FdfDSNDCQLSoGLNPjyHLcqoodcM9lvQEnPsXQ931maR2hK
        jLiDcCFpSPMLa+0aFMfZHP9bfmS7lDj7MjWZmv7hIGEFYz1LD+uF12rhbpzpsdmo
        nWSQlhjly2elwsoV2QIDAQAB`;
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(pubKey);
        let result = '';
        if(str) {
            result = encrypt.encrypt(str.toString())
        }
        return result;
    },
    decryptRsaCode(str) {
        var decrypt = new JSEncrypt();
        var PRIVATE_KEY = `MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBALhUmNypmCIBk5GU
        6xUnf3iSBfqPUhWfwQZzY7ESW4tLYV18NI0MJAtKgYs0+PIctyqih1wz2W9ASc+x
        dD3fWZpHaEqMuINwIWlI8wtr7RoUx9kc/1t+ZLuUOPsyNZma/uEgYQVjPUsP64XX
        auFunOmx2aidZJCWGOXLZ6XCyhXZAgMBAAECgYEAuCF5mWOoXr/oY1K5CiashQqJ
        AmPMgWFV9lWpziYiYCDqn/IEpKjuEIFaqm4+B8x7hBFa2dXJbAJyORBwp6XG4EwZ
        k+UlGvDxEeD/1NbVqY1VzIRboWuLGdycfTc5UCeCeHyz2yUmhIg+IgXEvaO2KMyE
        GohIPIytdyuyWW82T8kCQQDwgmwmfd4EwX5Sm5mViAaE/zhiacedBDVwrABQcby7
        p/ObhAsLL2odECz1EsPvvIlQ+4M92ZEofczi6JBF189vAkEAxDPhvGs2nQd79eue
        g/IHt4wmVvYwhQjAu9xGvMs5gdi9BG2EBLFr/B02oROBU7jHCkOQDlQFkF6Wg63/
        L0lLNwJAasUbigL6mxXjJH0bM3zNmCFNQLNBAH2r07cn9I5pgM1Qokt+M5r6+qgB
        rVKFVVpXg3IEk2KQxG8Ek/82fSsPVQJBAIpgAOvPPaRAHAgUFib+sryb5opcdVnO
        E4gq0eTi+3r9bknKJ1rb+snDnLy7sfFnF1HA1r+UOJxaaZMTSy+BZL8CQAy4XxOx
        MMB3vQEORY7Lbs0eabSptfKGnVBSUMttSBOoKPebed1ODKaeGqkGi5JYMIoFVMux
        LpnAmFJFwnAz7jM=`
        decrypt.setPrivateKey(PRIVATE_KEY);
        var uncrypted = decrypt.decrypt(str);
        return uncrypted;
    }

}