<template>
    <div class="tmp-btn">
        <template>
            <div class="btn-mask" :class="`mask-${type}`" @click.stop="handleClick"></div>
            <span  class="btn" :class="btnClass" @click.stop="handleClick">{{value}}</span>
        </template>
    </div>
</template>

<script>
export default {
    name: 'MyTableBtn',
    props: {
        type: { // 按钮组
            type: String,
            default: 'primary'
        },
        disabled:{
            type: Boolean,
            default: false
        },
        value:{
            type: String,
            default: '添加'
        }
    },
    computed:{
        btnClass(){
            return {
                primary: this.type === 'primary',
                info: this.type === 'info',
                disabled: this.type === 'disabled'
            }
        },
        // buttonDisabled() {
        //     return this.disabled || {}.disabled;
        // }
    },

    methods: {
        /**
         * 点击事件
         */
        handleClick(evt) {
            this.$emit('click', evt);
        }
    }
};
</script>

<style lang="scss" scoped>
    .tmp-btn {
        position: relative;
        user-select: none;
        white-space: nowrap;
        height: 32px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        width: fit-content;
        /**默认样式按钮 */
        .primary {
            background: #3270FF;
            border: 1px solid #3270FF;
            color: #FFF;
            cursor: pointer;
        }
        .primary:hover{
            background: #3270FF;
            color: #FFF;
        }
        .primary:active{
            background: #3270FF;
            color: #FFF;
        }
        /** 空按钮*/
        .info  {
            background: #FFF;
            color: #595959;
            border-radius: 4px;
            border: 1px solid #D9D9D9;
            cursor: pointer;
        }
        .info:hover{
            border-color: #3270FF;
            color: #3270FF;
        }
        .info:active{
            border-color: #3270FF;
            color: #3270FF;
        }
        .btn {
            display: inline-block;
            height: 32px;
            line-height: 30px;
            padding: 0px 12px;
            width: 100%;
            box-sizing: border-box;
            border-radius: 4px;
            font-size: 14px;
        }
        .disabled {
            background: #FAFAFA;
            border-radius: 4px;
            border: 1px solid #D9D9D9;
            color: #BFBFBF;
            cursor: not-allowed;
        }
        .btn-mask {
            display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0, .1);
            cursor: pointer;
        }
        &:hover .mask-primary {
            display: block;
        }
    }
</style>