import Cookies from 'js-cookie'

const TokenKey = 'MoveCar-Token'
const Username = 'user-name'
const OrgId = 'OrgId'
const OrgCode = 'OrgCode'
const Route = 'Route'
const OrgName = 'OrgName'
const Password = 'Password'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function getUsername() {
    return Cookies.get(Username)
}

export function setUsername(username) {
    return Cookies.set(Username, username)
}

export function removeUsername() {
    return Cookies.remove(Username)
}

export function getOrgName() {
    return Cookies.get(OrgName)
}

export function setOrgName(orgName) {
    return Cookies.set(OrgName, orgName)
}

export function getOrgId() {
    return Cookies.get(OrgId)
}

export function setOrgId(orgId) {
    return Cookies.set(OrgId, orgId)
}

export function getOrgCode() {
    return Cookies.get(OrgCode)
}

export function setOrgCode(orgCode) {
    return Cookies.set(OrgCode, orgCode)
}
export function getRoute() {
    return Cookies.get(Route)
}

export function setRoute(route) {
    return Cookies.set(Route, route)
}

export function removeRout() {
    return Cookies.remove(Route)
}

export function setPassword(password) {
    return Cookies.set(Password, password)
}

export function getPassword() {
    return Cookies.get(Password)
}